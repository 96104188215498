<template>
  <div class="row row-height postion-relative pa-3">
    <div v-if="showOverlay" class="wizard-absolute-members"></div>

    <FullscreenLoader v-if="loader" />
    <v-sheet v-else class="overflow-hidden col-md-12 change-height" style="position: relative; border-radius: 8px">

      <div id="w-gray" class="row form-row-height" style="padding-bottom: 50px">

        <div v-if="showOverlay" class="wizard-absolute">

          <div v-if="dialog" class="modal-wizard-check-members">

            <v-card class="col-md-12">

              <div class="row text-center justify-center orange-underline-wizard">
                <div class="text-center justify-center col-md-10 font-weight-bold mt-3 mb-3">
                  <span style="margin-left: 65px"> Choose an Action</span>
                </div>
                <div class="col-md-1 mt-3 mr-3">
                  <span class="text-end">
                    <i class="fa fa-times text-gray pointer"
                      style="font-size: 22px"
                      @click="closeModal"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="mt-4 mb-10">
                <div class="justify-center row text-center padding-on-mobile"
                v-if="canPerformActions(tmpM)">
                  <div class="blue-success-modal mt-10 col-md-10 justify-center pointer" @click="next('checkNowDialog')">
                    <img src="/svg/checkin.svg" />
                    <div class="success-modal-blue-text mt-2 mb-2">
                      Check In Now
                    </div>
                  </div>
                </div>

                <div class="justify-center row mt-2 text-center mb-6 mt-10 padding-on-mobile"
                    v-if="canPerformActions(tmpM)"
                >
                  <div class="green-success-modal col-md-10 justify-center pointer" @click="next('futureVisitDate')">
                    <img src="/svg/calendar-plus.svg" />
                    <div class="success-modal-green-text mt-3 mb-2">
                      Set Future Visit Date
                    </div>
                  </div>
                </div>

                <div class="justify-center row mt-2 text-center mb-6 padding-on-mobile" v-if="isActiveMember(tmpM)">
                  <div class="red-checkout-modal col-md-10 justify-center pointer" @click="singleUserVisit(tmpM)">
                    <img src="/svg/report-visit.svg" />

                    <div class="red-checkout-modal-text mt-2 mb-2">
                      Report Visit
                    </div>
                  </div>
                </div>

              </div>

            </v-card>

          </div>

          <div v-if="checkoutDialog" width="400" class="modal-wizard-check-members">
            <v-card class="col-md-12">

              <div class="row text-center justify-center orange-underline-wizard">
                <div class="text-center justify-center col-md-10 font-weight-bold mt-3 mb-3 text-center" >
                  <span style="margin-left: 65px"> Check Out</span>
                </div>
                <div class="col-md-2 mt-3">
                  <span class="text-end">
                    <i
                      class="fa fa-times text-gray pointer mr-6"
                      style="font-size: 22px"
                      @click="closeModal"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="row justify-center mt-10 padding-on-mobile orange-underline-wizard">
                <div class="col-xl-10 col-lg-10 text-center" @click="sickVisit = false" :class="!sickVisit ? 'well-visit' : 'sick-visit'">
                  <img src="/svg/reactangle-blank.svg" />
                  <div class="col-md-12">This was a Wellness Visit</div>
                </div>
                <div class="col-md-12 font-weight-bold text-center">OR</div>
                <div class="col-xl-10 col-lg-10 text-center mb-5" @click="sickVisit = true" :class="sickVisit ? 'well-visit' : 'sick-visit'">
                  <img src="/svg/reactangle-blank.svg" />
                  <div class="col-md-12">This was a Non-Wellness Visit</div>
                </div>
              </div>

              <div class="mt-8 mb-5">
                <div class="justify-center row mt-2 text-center mb-6 padding-on-mobile" >
                  <div class="red-checkout-modal col-md-10 justify-center pointer" @click="checkout" >
                    <img src="/svg/left-arrow-user-running.svg" />
                    <div class="red-checkout-modal-text mt-3 mb-2">
                      Check Out
                    </div>
                  </div>
                </div>
              </div>

            </v-card>
          </div>

          <div v-if="futureVisitDate" width="400" class="modal-wizard-check-members">
            <v-card class="col-md-12">

              <div class="row text-center justify-center orange-underline-wizard" >
                <div  class="text-center justify-center col-md-10 font-weight-bold mt-3 mb-3" >
                  <span class="ml-6"> Set a future visit date </span>
                </div>
                <div class="col-md-1 mt-3 mr-3">
                  <span class="text-end"
                    ><i
                      class="fa fa-times text-gray pointer"
                      style="font-size: 22px"
                      @click="closeModal"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="row justify-center mt-10">
                <v-card>
                  <v-date-picker
                    header-color="black"
                    v-model.trim="futureDate"
                    show-adjacent-months
                    no-title
                    id="custom-date-picker"
                    scrollable
                    :show-current="true"
                    :color="'#084ba7'"
                    :weekday-format="parseDate"
                    class="custom-date-picker"
                    next-icon="fa-solid fa-caret-right"
                    prev-icon="fa-solid fa-caret-left"
                  >
                  </v-date-picker>
                </v-card>
              </div>

              <div class="justify-center row mt-2 text-center padding-on-mobile" >
                <div lass="green-success-modal col-md-10 mt-10 mb-5 justify-center pointer" @click="setFutureDate" >
                  <img src="/svg/calendar-plus.svg" />
                  <div class="success-modal-green-text mt-3 mb-2">
                    Set Future Visit Date
                  </div>
                </div>
              </div>

            </v-card>
          </div>

          <div v-if="paymentDialog" width="400" class="modal-wizard-check-members" >
            <v-card class="col-md-12">

              <div class="row text-center justify-center orange-underline-wizard" >
                <div class="text-center justify-center col-md-10 font-weight-bold mt-3 mb-3" >
                  Member Payment Required
                </div>
                <div class="col-md-1 mt-3 mr-3">
                  <span class="text-end">
                    <i
                      class="fa fa-times text-gray pointer"
                      style="font-size: 22px"
                      @click="closeModal"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="row col-md-12 justify-center">
                <div
                  class="font-weight-bold col-md-12 ml-4"
                  style="font-size: 14px"
                >
                  The member’s plan requires payment from the member in the
                  amount of:
                  <span class="font-weight-bolder" style="font-size: 18px"
                    >${{ checkoutAmount }}</span
                  >
                </div>
              </div>

              <div class="mt-8 justify-center row text-center">
                <div class="blue-success-modal mt-5 col-md-10 justify-center pointer" @click="next('proccessPaymentDialog')" >
                  <img src="/svg/payment-process.svg" />

                  <div class="success-modal-blue-text mt-4 mb-2">
                    Process Member Payment Now
                  </div>
                </div>
              </div>

              <div class="col-md-12 justify-center text-center mt-3 mb-3 font-weight-bold" >
                OR
              </div>

              <div class="justify-center row mt-1 text-center mb-15 pointer" @click="next('sendPaymentLinkDialog')" >
                <div class="green-success-modal col-md-10 justify-center">
                  <img src="/svg/send-green-icon.svg" />
                  <div class="success-modal-green-text mt-4 mb-2">
                    Send Payment Link to Member
                  </div>
                </div>
              </div>

            </v-card>
          </div>

          <div v-if="proccessPaymentDialog" width="400" class="modal-wizard-check-members" >
            <v-card class="col-md-12">

              <div class="row text-center justify-center orange-underline-wizard" >
                <div class="text-center justify-center col-md-9 font-weight-bold mt-3 mb-3" >
                  Process member payment now
                </div>
                <div class="col-md-1 mt-3 mr-3">
                  <span class="text-end"
                    ><i
                      class="fa fa-times text-gray pointer"
                      style="font-size: 22px"
                      @click="closeModal"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="row col-md-12 ml-1" style="padding: 15px">
                <div class="col-md-12 no-padding">
                  <v-label>First Name on Card</v-label>
                  <v-text-field
                    solo
                    hide-details
                    v-model="cardFirstName"
                  ></v-text-field>
                </div>
                <div class="col-md-12 no-padding">
                  <v-label>Last Name on Card</v-label>
                  <v-text-field
                    solo
                    hide-details
                    v-model="cardLastName"
                  ></v-text-field>
                </div>
                <div class="col-md-12 no-padding mt-0 mb-0">
                  <v-label>Card Number</v-label>
                  <v-text-field
                    hide-details
                    solo
                    v-model="cardNumber"
                  ></v-text-field>
                </div>
                <div class="row no-padding mt-0 mb-0">
                  <div class="col-md-6 mt-0 pl-0">
                    <v-label>Exp Date</v-label>
                    <v-menu
                      ref="menu"
                      :close-on-content-click="true"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="cardExpire"
                          readonly
                          v-bind="attrs"
                          solo
                          hide-details=""
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="cardExpire" no-title scrollable>
                      </v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-md-6 mt-0 pr-0">
                    <v-label>CCV</v-label>
                    <v-text-field
                      solo
                      hide-details
                      v-model="cardCvv"
                    ></v-text-field>
                  </div>
                  <div class="col-md-6 mt-0 pl-0">
                    <v-label>Zip Code</v-label>
                    <v-text-field solo hide-details v-model="cardZipCode" >
                    </v-text-field>
                  </div>
                  <div class="col-md-6 mt-0 pr-0">
                    <v-label>Amount</v-label>
                    <v-text-field solo hide-details v-model="cardAmount">
                    </v-text-field>
                  </div>
                </div>
              </div>

              <div class="mt-3 mb-5 justify-center row pointer text-center" @click="payment('card')" >
                <div class="blue-success-modal mt-5 col-md-11 justify-center">
                  <img src="/svg/payment-process.svg" />

                  <div class="success-modal-blue-text mt-4 mb-4">
                    Process Member Payment Now
                  </div>
                </div>
              </div>

            </v-card>
          </div>

          <div v-if="sendPaymentLinkDialog" width="400" class="modal-wizard-check-members" >
            <v-card class="col-md-12">
              <div class="row text-center justify-center orange-underline-wizard">
                <div class="text-center justify-center col-md-10 font-weight-bold mt-3 mb-3" >
                  Send payment link to member
                </div>
                <div class="col-md-1 mt-3 mr-3">
                  <span class="text-end">
                    <i
                      class="fa fa-times text-gray pointer"
                      style="font-size: 22px"
                      @click="closeModal"
                    ></i>
                  </span>
                </div>
              </div>
              <div class="col-md-12">
                <div
                  class="row col-md-12 justify-center font-weight-bold"
                  style="font-size: 14px"
                >
                  <span class="mt-4 ml-2">
                    Confirm member’s email and phone to send payment link via
                    email and/or text.
                  </span>
                </div>
                <div class="row col-md-12 justify-center pl-4 pl-4">
                  <div class="col-md-12 mb-5 no-padding">
                    <v-label> Member Email</v-label>
                    <v-text-field solo v-model="paymentEmail"></v-text-field>
                  </div>
                  <div class="col-md-12 no-padding">
                    <v-label> Member Phone</v-label>
                    <v-text-field solo v-model="paymentPhone"></v-text-field>
                  </div>
                </div>

                <div
                  class="justify-center row mt-1 text-center mb-6 pointer col-md-12"
                  @click="payment('now')"
                >
                  <div class="green-success-modal col-md-12 ml-1">
                    <img src="/svg/send-green-icon.svg" />

                    <div class="success-modal-green-text mt-4 mb-2">
                      Send Payment Link to Member
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </div>

          <div v-if="checkNowDialog" width="400" class="modal-wizard-check-members margin-top-wizard-card" >
            <v-card class="col-md-12">
              <div class="row text-center justify-center orange-underline-wizard" >
                <div class="text-center justify-center col-md-10 font-weight-bold mt-3 mb-3" >
                  Check Member In Now
                </div>
                <div class="col-md-1 mt-3 mr-3">
                  <span class="text-end">
                    <i
                      class="fa fa-times text-gray pointer"
                      style="font-size: 22px"
                      @click="closeModal"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="row justify-center mt-10">
                <div style="width: 90%">
                  <v-label class="text-gray">First Name</v-label>
                  <v-text-field
                    solo
                    disabled
                    v-model="tmpM.firstName"
                  ></v-text-field>
                </div>
                <div style="width: 90%">
                  <v-label class="text-gray">Last Name</v-label>
                  <v-text-field
                    solo
                    disabled
                    v-model="tmpM.lastName"
                  ></v-text-field>
                </div>
                <div style="width: 90%" v-if="tmpM.email">
                  <v-label class="text-gray">Email</v-label>
                  <v-text-field solo v-model="tmpM.email"></v-text-field>
                </div>
                <div style="width: 90%" v-else>
                  <v-label class="text-gray">Email</v-label>
                  <v-text-field solo v-model="email"></v-text-field>
                </div>
                <div style="width: 90%" v-if="tmpM.phone">
                  <v-label class="text-gray">Phone</v-label>
                  <v-text-field solo v-model="tmpM.phone"></v-text-field>
                </div>
                <div style="width: 90%" v-else>
                  <v-label class="text-gray">Phone</v-label>
                  <v-text-field solo v-model="phone"></v-text-field>
                </div>
              </div>

              <div class="mt-5 justify-center row text-center">
                <div class="blue-success-modal mt-5 col-md-10 justify-center pointer mb-5" @click="checkMemberNow" >
                  <img src="/svg/checkin.svg" />
                  <div
                    class="success-modal-blue-text mb-3 mt-2"
                    style="font-size: 15px"
                  >
                    Confirm / Enter Information and Check In
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>

        <div class="col-md-12">
          <v-row align="center" justify="center">
            <div class="col-md-12 navigationWidth no-padding">
              <v-row class="blue-underline mb-1">

                <v-col class="col-xl-2 col-md-2 cols-4 pt-2 pb-0" cols="4">
                  <div class="col-md-12">
                    <h3 class="form-title font col-md-12 font-18">Members</h3>
                  </div>
                </v-col>

                <v-col class="col-xl-3 col-md-5 cols-8" cols="8">
                  <div class="col-md-12 d-flex no-padding pt-4">
                    <v-text-field
                      class="input-control mr-5"
                      prepend-inner-icon="mdi-magnify"
                      v-model="searchQuery"
                      @input="searchMembers2"
                      light
                      dense
                      outlined
                      placeholder="Search Members"
                    ></v-text-field>
                    <v-btn
                      class="text-gray filter-btn"
                      @click.stop="showFilterSideBar"
                    >
                      <img src="/svg/filter-icon.svg" />
                      Filters
                    </v-btn>
                  </div>
                </v-col>

                <v-col
                  class="col-xl-7 col-md-5 pt-6 pr-6 cols-12 text-end"
                  cols="12"
                  align="end"
                >
                  <v-menu offset-y>
                    <template v-slot:activator="{}">
                      <v-btn
                        class="my-1 mr-4 gray-border-buttons"
                        @click="exportData('tblData')"
                      >
                        <img src="/svg/download.svg" />

                        Export
                      </v-btn>
                      <v-btn
                        v-if="user.role == 'admin' || user.role == 'moderator'"
                        @click="newMemberDialog = true"
                        class="my-1 gray-border-buttons"
                      >
                        <img class="mr-1" src="/svg/plus.svg" />

                        New
                      </v-btn>
                    </template>
                  </v-menu>
                </v-col>
              </v-row>

              <v-simple-table id="tblData" style="display: none">

                <thead>
                  <tr>
                    <th class="text-left">MEMBER NAME</th>
                    <th class="text-left">CLIENT NAME</th>
                    <th class="text-left">LOCATION</th>
                    <th class="text-left">STATUS</th>
                    <th class="text-left">LAST VISIT</th>
                    <th class="text-left">NEXT VISIT</th>
                  </tr>
                </thead>

                <tbody id="remove-hover">
                  <tr
                    v-for="(item, index) in members"
                    :key="index"
                    class="text-gray"
                  >
                    <td v-if="item.firstName && item.lastName">
                      <a>{{ item.firstName }} {{ item.lastName }} </a>
                    </td>
                    <td v-else><a>/ </a></td>
                    <td v-if="item.employer && item.employer.companyName">
                      {{ item.employer.companyName }}
                    </td>
                    <td>
                      {{ item.location }}
                    </td>
                    <td>
                      {{ item.status }}
                    </td>
                    <td v-if="item.lastVisit">
                      {{ item.lastVisit | moment("DD/MM/YYYY") }}
                    </td>
                    <td v-else>/</td>
                    <td v-if="item.nextVisit">
                      {{ item.nextVisit | moment("DD/MM/YYYY") }}
                    </td>
                    <td v-else>/</td>
                  </tr>
                </tbody>
              </v-simple-table>

              <div v-if="tableLoading">
                <v-progress-linear
                  class="text-center justify-center mt-0 "
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </div>
            </div>
              <!-- <div v-else> -->
          </v-row>

          <v-row class="col-md-12" v-if="members.length < 1">
            <v-col md="12">
              No members to show
              <a v-if="$role != 'user'" @click="newMemberDialog = true">- Click Here to Add a Member.</a>
            </v-col>
          </v-row>

            <div v-else class="members-table">
                <v-data-table
                    class="elevation-1 pa-2 "
                    :headers="tableHeaders"
                    :items="members"
                    :search="search"
                    :mobile-breakpoint="0"
                    :hide-default-header="false"
                    :hide-default-footer="true"
                    :items-per-page="itemsPerPage"
                    :page="page"
                    :footer-props="{
                        'items-per-page-options': itemsPerPageOptions,
                    }"
                >
                    <template v-slot:body="{ items }">
                        <tr v-for="item in items" :key="item.id" class="pa-1">
                        <td v-if="item.firstName" class="pa-1 font-14">
                            <a @click="singleUser(item)"
                            >{{ item.firstName }} {{ item.lastName }}
                            </a>
                        </td>
                        <td v-else><a @click="singleUser(item)">/ </a></td>
                        <td
                            v-if="item.employer && item.employer.companyName"
                            class="pa-1 text-gray font-14"
                        >
                            {{ item.employer.companyName }}
                        </td>
                        <td v-else class="pa-1 font-14">/</td>
                        <td class="pa-1 text-gray font-14">
                            {{ item.location }}
                        </td>
                        <td class="pa-1 text-gray font-14" >
                            {{ memberStatus(item) }}
                        </td>
                       
                        <td v-if="item.lastVisit" class="pa-1 text-gray font-14">
                            {{ item.lastVisit | moment("MM/DD/YYYY") }}
                        </td>
                        <td v-else class="pa-1 text-gray font-14">/</td>
                        <td v-if="item.nextVisit" class="pa-1 text-gray font-14">
                            {{ item.nextVisit | moment("MM/DD/YYYY") }}
                        </td>
                        <td v-else class="pa-1 text-gray font-14">/</td>

                        <td class="text-end pa-1 pr-4 font-14" v-if="canPerformActions(item, 'table')">
                            <svg
                            @click="singleItemPopUp(item)"
                            class="pointer"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M6.85742 13.7143L10.3717 16.5257C10.4661 16.6035 10.5766 16.6592 10.6952 16.6888C10.8138 16.7185 10.9376 16.7213 11.0574 16.6972C11.1784 16.6745 11.2931 16.626 11.3937 16.555C11.4943 16.484 11.5784 16.3922 11.6403 16.2857L17.1431 6.85718"
                                stroke="#09951F"
                                stroke-width="1.71429"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M12.0003 23.1429C18.1543 23.1429 23.1431 18.1541 23.1431 12C23.1431 5.846 18.1543 0.857178 12.0003 0.857178C5.84625 0.857178 0.857422 5.846 0.857422 12C0.857422 18.1541 5.84625 23.1429 12.0003 23.1429Z"
                                stroke="#09951F"
                                stroke-width="1.71429"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            </svg>
                        </td>
                        </tr>
                    </template>
                    <template v-slot:footer>
                        <v-divider class="mt-5 mb-3"></v-divider>
                        <v-row justify="end" align="center" no-gutters>
                            <v-col cols="auto" class="d-flex align-center mr-7">
                                <span class="rows-per-page">Rows per page:</span>
                            </v-col>

                            <v-col cols="auto" class="d-flex align-center mr-5">
                                <v-select
                                    class="pagination-items-per-page"
                                    v-model="itemsPerPage"
                                    :items="itemsPerPageOptions"
                                    hide-details
                                    @change="updateItemsPerPage"
                                ></v-select>
                            </v-col>

                            <v-col cols="auto" class="d-flex align-center mr-2">
                                <span class="page-number">{{ pageStart }} - {{ pageEnd }} of {{ totalMembers }}</span>
                            </v-col>

                            <v-col cols="auto mr-13">
                                <v-pagination
                                    density="compact"
                                    v-model="page"
                                    @input="updatePage"
                                    class="ml-4"
                                    :length="totalPages"
                                    :total-visible="5"
                                >
                                </v-pagination>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </div>
        </div>
      </div>
            <!-- </div>
          </v-row>
        </div>
      </div> -->
      <v-navigation-drawer
        class="userFilterPosition"
        width="350"
        style="height: 100vh !important"
        v-model.trim="drawer"
        absolute
        temporary
      >
        <div class="filter-sideBar">
          <div>
            <div class="row no-padding col-md-12 justify-space-between">
              <h3 class="font pl-3 mt-1">Member Filters</h3>
              <h1 class="pointer mr-3" @click.stop="drawer = !drawer">x</h1>
            </div>
            <v-divider></v-divider>
            <div class="col-md-12">
              <div
                class="row col-md-12 justify-space-between no-padding no-margin"
              >
                <div class="no-padding font filter-items-font font-weight-bold">
                  Selected Filter
                </div>
                <div
                  class="underline-text pointer text-gray filter-items-font"
                  @click="clearAll"
                >
                  Clear All
                </div>
              </div>
            </div>
            <div class="overflow-fitlers-buttons">
              <div class="row mb-1 col-md-12">
                <v-btn
                  v-for="(item, index) in selectedArray.concat(
                    statusArray,
                    selectedClients
                  )"
                  :key="index"
                  class="text-center mb-1 ml-2 mr-2 filter-btn"
                  small
                >
                  <span v-html="item.text"></span>
                  <h5 class="ml-3" @click="removeFilter(item)">x</h5>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <v-expansion-panels
                accordion
                multiple
                v-model.trim="panel"
                class="no-shadow no-padding user-expansion-panels"
              >
                <v-expansion-panel class="no-shadow no-padding mb-3">
                  <v-expansion-panel-header
                    class="font-weight-black change-padding-filter filter-items-font"
                    >Status
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="no-padding content-filter">
                    <v-text-field
                      solo
                      class="filter-serach-input mb-4"
                      v-model.trim="status"
                      @input="searchStatus()"
                      dense
                      :hide-details="true"
                      placeholder="Search"
                      append-icon="fa fa-search"
                    ></v-text-field>
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showStatus.all"
                      v-model.trim="filter.status.all"
                      class="no-padding small-text radio-buttons-filters"
                      hide-details
                      :on-icon="'mdi-square'"
                      @click="
                        pushFilter(
                          { text: 'All', value: 'all' },
                          filter.status.all
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Pending"
                      color="primary"
                      v-if="showStatus['pending']"
                      v-model.trim="filter.status.pending"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      :on-icon="'mdi-square'"
                      @click="
                        pushFilter(
                          { text: 'Pending', value: 'pending' },
                          filter.status.pending
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Checked in"
                      color="primary"
                      v-if="showStatus['checkedIn']"
                      v-model.trim="filter.status.checkedIn"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      :on-icon="'mdi-square'"
                      @click="
                        pushFilter(
                          { text: 'checkedIn', value: 'checkedIn' },
                          filter.status.checkedIn
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Active"
                      color="primary"
                      v-if="showStatus.active"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.status.active"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushFilter(
                          { text: 'Active', value: 'active' },
                          filter.status.active
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Expired"
                      color="primary"
                      v-if="showStatus['expired']"
                      v-model.trim="filter.status.expired"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushFilter(
                          { text: 'Expired', value: 'expired' },
                          filter.status.expired
                        )
                      "
                    >
                    </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="no-shadow no-padding mt-3 mb-3">
                  <v-expansion-panel-header
                    class="font-weight-black change-padding-filter filter-items-font"
                    >Clients
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="no-padding content-filter" style="height:90px; overflow:scroll;">
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showClient['ALL']"
                      v-model.trim="filter.clients['ALL']"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                      pushtoClientFilter(
                        { text: 'ALL', value:'ALL'},
                        filter.clients['ALL']
                      )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox v-for="(item,index) in clients" :key="index"
                    :label=item
                    color="primary"
                    v-if="showClient[item]"
                    v-model.trim="filter.clients[item?.toLowerCase()]"
                    :on-icon="'mdi-square'"
                    class="no-padding radio-buttons-filters"
                    hide-details
                    @click="
                      pushtoClientFilter(
                        { text: item, value:item?.toLowerCase() },
                        filter.clients[item?.toLowerCase()]
                      )
                    "
                  >
                  {{ item }}
                  </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <div>
            <v-divider></v-divider>
            <v-btn
              class="blue-buttons col-md-12 mt-3 mb-md-3 mb-xl-0"
              @click="fetchMemberWithPagination()"
              >View Result
            </v-btn>
          </div>
        </div>
      </v-navigation-drawer>
    </v-sheet>
    <check-eligibility-new-member-modal
      @closeNewMemberModal="newMemberDialog = false"
      :newMemberDialog="newMemberDialog"
      v-if="newMemberDialog"
    />

    <router-view></router-view>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>

    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"> </errorModal>
  </div>
</template>
<script>
import api from "../../../../services/api";
import { mapGetters } from "vuex";
import XLSX from "xlsx";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import CheckEligibilityNewMemberModal from "../SharedComponents/CheckEligibilityNewMemberModal.vue";
import moment from "moment";
import FullscreenLoader from "../../../Common/FullscreenLoader.vue";
export default {
  components: { successModal, errorModal, CheckEligibilityNewMemberModal, FullscreenLoader },
  data() {
    return {
      readyToShow: false,
      search: '',
      pagination: {
      rowsPerPage: 30
    },
      tableHeaders: [
        {text: 'MEMBER NAME ', value:'memberName', align: 'start'},
        {text: 'CLIENT NAME', value:'clientName', align: 'start'},
        {text: 'LOCATION', value:'location', align: 'start'},
        {text: 'STATUS', value:'status', align: 'start'},
        {text: 'LAST VISIT', value:'lastVisit', align: 'start'},
        {text: 'NEXT VISIT', value:'nextVisit', align: 'start'},
        {text: 'ACTIONS', align: 'end'},
      ],
      subscriberItems: ["Self", "Spouse", "Dependent"],
      menu: "",
      items: [],
      location: "",
      tmpM: null,
      filterItems: false,
      email: "",
      phone: "",
      members: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      overlay: false,
      display: true,
      filterArray: [],
      clients: [],
      selectedClients: [],
      windowWidth: window.innerWidth,
      noResultMessage: false,
      drawer: null,
      checkoutDialog: false,
      futureVisitDate: false,
      date: "",

      showStatus: {
        all: true,
        active: true,
        pending: true,
        expired: true,
        inactive: true,
        checkedIn: true,
      },
      showType: {
        all: true,
        eleven: true,
        boeing: true,
        catepillar: true,
      },
      menu: false,
      menuCheckIn: false,
      userStatus: [
        {
          text: "ALL",
          value: "all",
        },

        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Expired",
          value: "expired",
        },
      ],
      userType: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "7-Eleven",
          value: "7-Eleven",
        },
        {
          text: "Boeing",
          value: "boeing",
        },
        {
          text: "Catepillar",
          value: "Catepillar",
        },
      ],
      selectedArray: [],
      selectedTypeArray: [],
      getUsers: [],
      panel: [0, 1],
      memberId: "",
      filterUse: false,
      type: "",
      status: "",
      statusArray: [],
      typeArray: [],
      showClient:{},
      filter: {
        clients: {},
        status: {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
          checkedIn: false,
        },
        type: {
          all: false,
          user: false,
          admin: false,
        },
        lastVisit: "",
      },
      wizardData: {
        steps: 1,
      },
      dialog: false,
      newMemberDialog: false,
      zIndex: 999,
      absolute: true,
      drawer: false,
      sickVisit: false,
      paymentDialog: false,
      proccessPaymentDialog: false,
      sendPaymentLinkDialog: false,
      checkNowDialog: false,
      date: "",
      showOverlay: false,
      open: false,
      checkoutAmount: "",
      checkNowDate: "",
      futureDate: "",
      paymentEmail: "",
      paymentPhone: "",
      cardFirstName: "",
      cardLastName: "",
      cardNumber: "",
      cardCvv: "",
      cardExpire: "",
      cardZipCode: "",
      cardAmount: "",
      allMembers: [],
      noResultMessageFilter: "",
      selectedLastVisitArray: [],
      noDataForThisLocationMessage: "",
      newMember: {},
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      eligibilitySteps: [
        {
          text: "TPA Verified",
          show: false,
          verify: false,
        },
        {
          text: "Employer & Plan Verified",
          show: false,
          verify: false,
        },
        {
          text: "Platform and Claim System Exchange",
          show: false,
          verify: false,
        },
        {
          text: "Claim System to Platform",
          show: false,
          verify: false,
        },
      ],

      locations: [],
      showOverlay: false,
      errorModal: false,
      dialog: false,
      loader: false,
      errorMessage: "",
      activeMember: false,
      totalMembers: 0,
      tableLoading: false,
      page: 1,
      itemsPerPage: 10,
      itemsPerPageOptions: [5, 10, 20, 50],
      searchQuery: "",
      debounceTimer: null,  // Timer to hold the debounce timeout
    };
  },
  computed: {
    pageStart() {
      return (this.page - 1) * this.itemsPerPage + 1;
    },
    pageEnd() {
      return Math.min(this.page * this.itemsPerPage, this.totalMembers);
    },
    totalPages() {
      return Math.ceil(this.totalMembers / this.itemsPerPage);
    },
    filteredMembers() {
 
 return this.members.filter(item => {
   let search = this.search.toLowerCase()
   let match = false
   if(item.firstName)match = match || item.firstName.toLowerCase().includes(search)
   if(item.lastName)match = match || item.lastName.toLowerCase().includes(search)
   if(item.employer?.companyName)match = match || item.email.toLowerCase().includes(search)
   if(item.location)match = match || item.location.toLowerCase().includes(search)
   if(item.subscriptionStatus)match = match || item.subscriptionStatus.toLowerCase().includes(search)
   return match
 })
},
    returnFormatedDate() {
      return moment(this.newMember.dob).format("MM/DD/YYYY");
    },
    ...mapGetters([
      "showRoutePathForFilter",
      "getLocationId",
      "getFilterBy",
      "getFilterMember",
    ]),
    routeParams() {
      return this.$route.params.name;
    },
    singleEmp() {
      let emp = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("employer"));
      }
      return emp;
    },
    arrayInFilters() {
      return this.members;
    },
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.getFilterBy) {
      this.filterMembers(this.getFilterBy);
    } else {
      this.fetchMemberWithPagination(true);
    }
  },
  watch: {
    showRoutePathForFilter: {
      handler(newValue, oldValue) {
        this.drawer = this.showRoutePathForFilter;
      },
      deep: true,
    },
    drawer: {
      handler(newValue, oldValue) {
        this.$store.commit("setNavItemFilter", this.drawer);
      },
      deep: true,
    },
    getLocationId: {
      handler(newValue, oldValue) {
        this.getItems();
      },

      deep: true,
    },
    getFilterBy: {
      handler(newValue, oldValue) {
        this.filterMembers(newValue);
      },

      deep: true,
    },
  },
  methods: {
    canPerformActions(item, type) {
        if (type === 'table') {
            return  item?.membershipStatus != 'canceled';
        }
        return item?.history?.membershipStatus != 'canceled';
    },
    memberStatus(item) {
        if (item.membershipStatus === 'canceled') {
            return "Canceled"
        } else {
            return item.subscriptionStatus
            ? item.subscriptionStatus.charAt(0).toUpperCase() +
            item.subscriptionStatus.slice(1) : '/'
        }
    },
    updateItemsPerPage(newVal) {
        this.itemsPerPage = newVal;
        this.page = 1;

        this.fetchMemberWithPagination();
    },
    updatePage(newVal) {
        this.page = newVal;
        this.fetchMemberWithPagination();
    },
    searchMembers2() {
        this.page = 1;

        // Clear the previous timeout if the user is still typing
        clearTimeout(this.debounceTimer);
        
        // Set a new timeout to call the search after a delay
        this.debounceTimer = setTimeout(() => {
            this.fetchMemberWithPagination();
        }, 500); 
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    lastVisitFilter(tag) {
      if (this.selectedLastVisitArray.length > 0) {
        let index = this.selectedLastVisitArray.findIndex((c) => c.text == tag);
        if (index == -1) {
          this.selectedLastVisitArray.push({ text: tag, value: tag });
        } else {
          this.selectedLastVisitArray.splice(index, 1);
        }
      } else {
        this.selectedLastVisitArray.push({ text: tag, value: tag });
      }
    },
    filterMembers(title) {
      let arr = [];

      api()
        .get(`/dpcproviders/members`)
        .catch((err) => {
          if (err) {
            if (err.response.status == 500) {
              this.$refs.error_modal.openPopUp(
                "There was an error, system admin is notified. Please try again later."
              );
            } else {
              this.members = [];
              this.noDataForThisLocationMessage = err.response.data;
            }
          }
        })
        .then((res) => {
          if (res && res.data && res.data.length > 0) {
            if (res.data.length < 1) {
              this.members = res.data;
              this.allMembers = res.data;
              this.filterArray = this.members;
              this.noResultMessageFilter = true;
            } else if (title) {
              this.members = [];
              this.noResultMessageFilter = false;

              let today = new Date();
              let todaysDate = today.toISOString();
              let apartDays305 = today.setDate(today.getDate() + 305);
              let apartDaysDate = new Date(apartDays305).toISOString();

              let today2 = new Date();
              let apartSixMonths = today2.setMonth(today2.getMonth() - 6);
              let apartSixMonthsParsed = new Date(apartSixMonths).toISOString();

              res.data.forEach((el) => {
                if (title == "checkIn") {
                  // if (el.nextVisit) {
                  //   if (
                  //       todaysDate.substring(0, 10) ==
                  //       el.nextVisit.substring(0, 10)
                  //   ) {
                  //     this.members.push(el);
                  //   }
                  // }
                  if (el.status == "checked in") {
                    this.members.push(el);
                  }
                }
                if (title == "expiringMembers") {
                  if (
                    el.status === "Expired" ||  
                    el.status === "Expiring" 
                  ) {
                    this.members.push(el);
                  }
                }
                if (title == "visitDate") {
                  if (el.lastVisit && el.lastVisit < apartSixMonthsParsed) {
                    this.members.push(el);
                  }
                }
                if (title == "pendingMembers") {
                  if (el.status == "Pending") {
                    this.members.push(el);
                  }
                }

                if (this.members.length < 1) {
                  this.noResultMessageFilter = true;
                }
              });
            } else {
              this.members = res.data;
              this.allMembers = res.data;
              this.filterArray = this.members;
              this.noResultMessageFilter = false;
            }
          }
        });

      return arr.length;
    },
    exportData(tableID, filename = "") {
      var elt = document.getElementById(tableID);
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      filename = filename ? filename + ".xls" : "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },

    singleItemPopUp(item) {
      this.memberId = item.id;
      this.tmpM = item;
      this.showOverlay = true;
      this.dialog = true;
    },
    checkMemberNow() {
      let data = {
        firstName: this.tmpM.firstName,
        lastName: this.tmpM.lastName,
        email: this.tmpM.email ? this.tmpM.email : this.email,
        phone: this.tmpM.phone ? this.tmpM.phone : this.phone,
        location: this.getLocationId ? this.getLocationId : this.tmpM.location,
        // memberId: this.memberId,
      };

      this.dialog = false;

      if (this.getLocationId) {
        data.location = this.getLocationId;
      }
      api()
        .post(`/dpcproviders/checkin/` + this.memberId, data)
        .catch((err) => {
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if (res) {
            this.next("closeModal");
            this.showOverlay = false;
            this.$refs.success_modal.openPopUp("Success");
          }
        });
    },
    closeSuccessModal() {
      this.getItems();
    },
    setFutureDate() {
      let data = {
        date: this.futureDate,
        //memberId: this.tmpM.id,
      };
      if (this.getLocationId) {
        data.location = this.getLocationId;
      }
      api()
        .post(`/dpcproviders/checkfuture/` + this.memberId, data)
        .catch((err) => {
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if (res) {
            this.next("closeModal");
            this.showOverlay = false;
            this.$refs.success_modal.openPopUp("Success");
          }
        });
    },
    checkout() {
      if (!this.getLocationId) {
        this.$refs.error_modal.openPopUp("Please Select Location First!");
        this.closeModal();
      } else {
        let data = {
          visit: !this.sickVisit ? "wellness" : "non-wellness",
          location: this.getLocationId,
        };
        api()
          .post(`/dpcproviders/checkout/` + this.memberId, data)
          .catch((err) => {
            if (err.response.status == 500) {
              this.$refs.error_modal.openPopUp(
                "There was an error, system admin is notified. Please try again later."
              );
            } else {
              this.$refs.error_modal.openPopUp(err.response.data);
            }
          })
          .then((res) => {
            if (res) {
              if (res.data.amount) {
                this.next("paymentDialog");
                this.checkoutAmount = res.data.amount;
              } else {
                this.next("closeModal");
                this.showOverlay = false;
                this.$refs.success_modal.openPopUp("Success");
              }
            }
          });
      }
    },
    payment(type) {
      let data = null;
      if (type == "card") {
        data = {
          type: "card",
          firstName: this.cardFirstName,
          lastName: this.cardLastName,
          cardNo: this.cardNumber,
          ccv: this.cardCvv,
          expire: this.cardExpire,
          zipCode: this.cardZipCode,
          amount: 60,
        };
      } else {
        data = {
          type: "email",
          email: this.paymentEmail,
          phone: this.paymentPhone,
          amount: 60,
        };
      }

      api()
        .post(`/dpcproviders/payment/` + this.memberId, data)
        .catch((err) => {
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if (res) {
            this.next("closeModal");
            this.showOverlay = false;
            this.$refs.success_modal.openPopUp("Success");
          }
        });
    },
    commitFilterNav() {
      if (this.drawer) {
        this.$store.commit("setNavItemFilter", false);
      } else {
        this.$store.commit("setNavItemFilter", true);
      }
    },
    parseDate(date) {
      const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },

    showFilterSideBar() {
      this.drawer = !this.drawer;
      if (this.drawer) {
        this.$store.commit("setNavItemFilter", this.drawer);
      } else {
        this.$store.commit("setNavItemFilter", false);
      }
    },
    next(modal) {
      this.wizardData.steps++;
      this.paymentDialog = false;
      this.proccessPaymentDialog = false;
      this.sendPaymentLinkDialog = false;
      this.checkNowDialog = false;
      this.dialog = false;
      this.futureVisitDate = false;
      this.checkoutDialog = false;
      this.showOverlay = true;
      if (modal == "closeModal") {
        this.closeModal();
      } else {
        this[modal] = true;
      }
    },

    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            all: true,
            eleven: true,
            boeing: true,
            catepillar: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            all: false,
            eleven: false,
            boeing: false,
            catepillar: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    pushFilter(item, checked) {
      if (checked) {
        if (item.value == "all") {
          this.statusArray = [];
          this.filter.status = {
            all: true,
            active: true,
            pending: true,
            expired: true,
            inactive: true,
            checkedIn: true,
          };
          this.statusArray.push({ text: "All", value: "all" });
        } else {
          this.statusArray.push(item);
        }
      } else {
        if (item.value == "all" || this.filter.status.all) {
          this.filter.status = {
            all: false,
            active: false,
            pending: false,
            expired: false,
            inactive: false,
            checkedIn: false,
          };
          this.statusArray = [];
        } else {
          this.statusArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.statusArray.splice(index, 1);
            }
          });
        }
      }
    },

    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.status.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },
    filterUsers() {
      if (!this.filter.status && !this.filter.type) {
        this.getItems("all");
      } else {
        this.getItems(this.filter.status);
      }
      this.filterUse = true;
      this.drawer = false;
    },
    clearAll() {
      this.filter.status = {
        all: false,
        active: false,
        pending: false,
        expired: false,
        inactive: false,
        checkedIn: false,
      };
      this.filter.type = {
        all: false,
        eleven: false,
        boeing: false,
        catepillar: false,
      };

      this.selectedArray = [];
      this.selectedTypeArray = [];
      // this.getItems("all");

      this.fetchMemberWithPagination();
    },
    removeFilter(item) {
      if (item.value == "ALL") {
        Object.keys(this.filter.clients).forEach((key) => {
          this.filter.clients[key] = false;
        });
        this.selectedClients = [];
      } else if (this.filter.clients[item.value]) {
        this.filter.clients[item.value] = false;
        this.selectedClients.splice(this.selectedClients.indexOf(item), 1);
      }
      if (this.filter.status[item.value]) {
        this.filter.status[item.value] = false;
        this.statusArray.splice(this.statusArray.indexOf(item), 1);
      } else if (item.value == "all") {
        this.statusArray = [];
        this.filter.status = {
          all: false,
          active: false,
          pending: false,
          expired: false,
          checkedIn: false,
        };
      }
    },

    fetchPageData() {},
    fetchMemberWithPagination(useLoader) {
        if (useLoader === true) {
            this.loader = useLoader
        }
        // Hide Filter Drawer
        this.drawer = false;

        // Show Loader
        // this.loader = true;
        this.tableLoading = true;

        let statusFilter = [];
        let clientFilter = [];

        Object.keys(this.filter.status).forEach(key => {
            const enabled = this.filter.status[key];

            if (enabled) {
                statusFilter.push(key)
            }
        });

        Object.keys(this.filter.clients).forEach(key => {
            const enabled = this.filter.clients[key];

            if (enabled) {
                clientFilter.push(key)
            }
        });

        const queryParams = new URLSearchParams();

        queryParams.append('pagination', 'true');
        queryParams.append('limit', this.itemsPerPage);
        queryParams.append('page', this.page);

        let url = '/dpcproviders/members'
        
        if (this.searchQuery && this.searchQuery !== '') {
            const searchQuery = this.searchQuery.trim().toLowerCase();
            queryParams.append('search', searchQuery);
            url = '/dpcproviders/members/paginatedsearch'
        }

        // Add status filter if present
        if (statusFilter.length > 0) {
            queryParams.append('status', statusFilter.join(','));
        }

        // Add client filter if present
        if (clientFilter.length > 0) {
            queryParams.append('clients', clientFilter.join(','));
        }

        api()
            .get(`${url}?${queryParams.toString()}`)
            .catch((err) => {
                if (err) {
                    this.tableLoading = false;
                    if (err.response.status == 500) {
                        this.$refs.error_modal.openPopUp(
                            "There was an error, system admin is notified. Please try again later."
                        );
                    } else {
                        this.members = [];
                        this.noDataForThisLocationMessage = err.response.data;
                    }
                }
            })
            .then((res) => {
                if (res && res.data) {
                    if (res.data.members && res.data.members.length > 0) { // IF MEMBER HAS VALUE

                      // Set DATA
                      this.members = res.data.members;
                      this.allMembers = res.data.members;
                      this.filterArray = this.members;
                      this.totalMembers = res.data.totalMembers

                      // FILTERS
                      this.members.forEach((element) => {
                        this.clients.push(element.employer.companyName);
                        this.showClient[element.employer.companyName] = true;
                        this.filter.clients[
                            element.employer.companyName?.toLowerCase()
                          ] = false;
                      });

                      this.filter.clients["ALL"] = false;
                      this.showClient["ALL"] = true;
                      const unique = (value, index, self) => {
                          return self.indexOf(value) === index;
                      };
                      this.clients = this.clients.filter(unique);

                      // this.statusArray = this.userStatus;
                      this.typeArray = this.userType;
                      this.selectedArray = [];
                      this.$store.commit("setNavItemFilter", false);
                      this.$refs.success_modal.closePopUp();

                    }
                    
                    if (res.data.members.length < 1) { // IF MEMBER HAS NO VALUE
                      // alert()
                      // this.noResultMessage = false; // NOT IN USE INSIDE TEMPLATE

                      // Remove Data
                      this.members = [];
                      this.allMembers = [];
                      this.filterArray = [];
                      this.totalMembers = 0;
                      
                    }

                    // Loaders
                    this.tableLoading = false;
                    // this.loader = false;
                }
            })
            .finally(() => {
                this.tableLoading = false; 
                this.loader = false; 
            });
    },
    getItems(type) {
      this.loader = true;
      if (this.getLocationId) {
        params += `&location=${this.getLocationId}`;
      }
      api()
        .get(`/dpcproviders/members` + params)
        .catch((err) => {
          if (err) {
            this.loader = false;
            if (err.response.status == 500) {
              this.$refs.error_modal.openPopUp(
                "There was an error, system admin is notified. Please try again later."
              );
            } else {
              this.members = [];
              this.noDataForThisLocationMessage = err.response.data;
            }
          }
        })
        .then((res) => {
          if (res && res.data) {
            this.loader = false;

            if (res.data.members) {
                this.members = res.data.members;
                this.allMembers = res.data.members;
                this.filterArray = this.members;
                this.totalMembers = res.data.totalMembers
            }
           

            this.members.forEach((element) => {
              this.clients.push(element.employer.companyName);
              this.showClient[element.employer.companyName] = true;
              this.filter.clients[
                element.employer.companyName?.toLowerCase()
              ] = false;
            });
            this.filter.clients["ALL"] = false;
            this.showClient["ALL"] = true;
            const unique = (value, index, self) => {
              return self.indexOf(value) === index;
            };
            this.clients = this.clients.filter(unique);
            if (res.data.members.length < 1) {
              this.noResultMessage = true;
            } else {
              this.noResultMessage = false;
            }

            // this.statusArray = this.userStatus;
            this.typeArray = this.userType;
            this.selectedArray = [];
            this.$store.commit("setNavItemFilter", false);
            this.$refs.success_modal.closePopUp();
          }
        });
    },
    pushtoClientFilter(item, checked) {
      if (checked) {
        if (item.value == "ALL") {
          this.selectedClients = [];
          Object.keys(this.filter.clients).forEach((key) => {
            this.filter.clients[key] = true;
          });
          this.selectedClients.push({ text: "All", value: "ALL" });
        } else {
          this.selectedClients.push(item);
        }
      } else {
        if (item.value == "ALL" || this.filter.clients["ALL"]) {
          Object.keys(this.filter.clients).forEach((key) => {
            this.filter.clients[key] = false;
          });
          this.selectedClients = [];
        } else {
          this.selectedClients.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedClients.splice(index, 1);
            }
          });
        }
      }
    },
    singleUser(item) {
      this.$store.commit("setSingleMemberProvider", item);
      this.$router.push(`/dpc-providers/members/member/` + item.id);
    },
    singleUserVisit(item) {
      this.$store.commit("setSingleMemberProvider", item);
      this.$router.push("/dpc-providers/members/visit-reporting/" + item.id);
    },
    isActiveMember(item) {
        if (!item) {
            return false
        }

        if (item.planStatus === 'error') {
            return false;
        }

        if (['expired'].includes(item.membershipStatus)) {
            return true
        }

        if (['canceled'].includes(item.membershipStatus)) {
            return false
        }

        return item.planStatus === 'active'
    },
    searchMembers(val) {
      val = val.trim();
      this.members = this.filterArray.filter((el) => {
        // Check full name and reversed full name
        if (el.firstName && el.lastName) {
          let fullName = el.firstName + " " + el.lastName;
          let reverseFullName = el.lastName + " " + el.firstName;
          if (fullName.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (
            reverseFullName.toLowerCase().includes(val.toLowerCase())
          ) {
            return true;
          }
        }
        if (el.firstName.toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (
          el.client &&
          el.client.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        } else if (el.status?.toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (
          el.lastVisit &&
          el.lastVisit?.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        } else if (
          el.employer &&
          el.employer.companyName.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        } else if (
          el.location &&
          el.location.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        } else if (
          el.nextVisit &&
          el.nextVisit.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        }
        return false;
      });

      this.filterItems = this.members.length < 1;
    },

    closeModal(page) {
      this.showOverlay = false;
      this.paymentDialog = false;
      this.proccessPaymentDialog = false;
      this.sendPaymentLinkDialog = false;
      this.futureVisitDate = false;
      this.checkoutDialog = false;
      this.checkNowDialog = false;
      this.dialog = false;
      this.checkoutDialog = false;
    },
  },
};
</script>
<style>
.v-application .elevation-1 {
  box-shadow: none !important;
}
table th {
  font-weight: 700, bold;
  font-size: 14px !important;
  line-height: 25px !important;
  letter-spacing: 0.2px !important;
  color: #9fa2b4 !important;
}

.actions-th-padding {
  padding-right: 85px !important;
}

#remove-hover tr:hover {
  background-color: transparent !important;
}

.modal-member-page.v-dialog {
  margin-top: 0%;
}

.v-date-picker-table > table > thead > tr > th {
  color: black !important;
  font-weight: bolder;
}

#months-div .v-slide-group__next,
.v-slide-group__prev {
  min-width: inherit;
}
.filter-sideBar {
  height: 60% !important;
}

</style>

<style scoped>
/* Remove border and background for the navigation buttons (prev/next) */
.theme--light.v-pagination .v-pagination__navigation {
  border: none !important;  /* Forcefully remove borders */
  background-color: transparent !important;  /* Remove background */
  box-shadow: none !important;  /* Remove shadows */
  padding: 0 !important;  /* Remove padding if needed */
}

/* Adjust font size and icon appearance for the navigation buttons */
.theme--light.v-pagination .v-pagination__navigation .v-btn__content {
  font-size: 20px;  /* Increase size for plain < and > */
}
</style>