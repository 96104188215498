<template>

  <!-- Loader -->
  <!-- <v-overlay v-if="loader">
    <v-progress-circular indeterminate :size="100" color="#326ab3" class="text-center mt-10 mb-5">
        <img src="/newapalylogoonlyblue.png" height="70" class="img-mobile" />
    </v-progress-circular>
  </v-overlay> -->

  <!-- Fullscreen Loader -->
  <FullscreenLoader v-if="loader" />


  <v-row v-else class="add-client-form no-padding pa-3">
    <!-- Success Modal -->
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>

    <!-- Tabs Container -->
    <div class="row blue-underline margin-l-11">

      <!-- Tabs -->
      <div class="d-flex col-md-12">
        <h3 
          class="form-title font ml-2 mt-3 mb-2 font-18 cursor-pointer" 
          :class="{'underline-text': tab == 'details'}" 
          @click="changeTab('details')"
        >
          Member Detail
        </h3>
        <h3 
          v-if="memberData.subscription" 
          class="form-title font ml-10 mt-3 mb-2 font-18 cursor-pointer" 
          @click="changeTab('subscription')" 
          :class="{'underline-text': tab == 'subscription'}"
        >
          Subscription
        </h3>
        <h3
          class="form-title font ml-10 mt-3 mb-2 font-18 cursor-pointer"
          :class="{'underline-text': tab == 'dependents'}"
          @click="changeTab('dependents')"
        >
          Dependents
        </h3>
      </div>
    </div>

    <!-- Details Tab -->
    <div v-if="tab == 'details'" class="member-details width-100">

      <!-- Line Loader -->
      <div v-if="loader">
        <v-progress-linear
          class="text-center justify-center mt-0 "
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </div>

      <!-- Contents -->
      <div class="col-12 col-md-12 mt-2">
        
        <!-- Form Content -->
        <v-form v-model.trim="valid" ref="form" class="row mt-2">
          <div class="col-12 col-md-9">

            <!-- Member Info -->
            <div class="row">
              <div class="col-12 col-md-4">
                <h3>Member Information</h3>
                <p>Enter details for member information</p>
              </div>
              <div class="col-12 col-md-8">
                <div class="row">
                  <div class="col-12 pb-0">
                    <label class="font" for="">First Name</label>
                    <v-text-field
                      required
                      disabled
                      :rules="requiredRules"
                      v-model.trim="memberData.memberInfo.firstName"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 pb-0 pt-0">
                    <label class="font text-gray" for="">Middle Name</label>
                    <v-text-field
                      v-model.trim="memberData.memberInfo.middleName"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 pb-0 pt-0">
                    <label class="font text-gray" for="">Last Name</label>
                    <v-text-field
                      required
                      disabled
                      :rules="requiredRules"
                      v-model.trim="memberData.memberInfo.lastName"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 pb-0 pt-0">
                    <label class="font text-gray" for="">Gender</label>
                    <v-text-field
                      required
                      disabled
                      :rules="requiredRules"
                      v-model="returnFullGender"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-md-6 pb-0 pt-0">
                    <label class="font text-gray" for="">Date Of Birth</label>
                    <v-text-field
                      required
                      disabled
                      :rules="requiredRules"
                      v-model="returnFormattedDate"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-md-6 pb-0 pt-0">
                    <label class="font text-gray" for="">Email</label>
                    <!-- <v-text-field
                      required
                      disabled
                      :rules="emailRules"
                      solo
                      v-model.trim="memberData.memberInfo.email"
                      
                    ></v-text-field> -->
                    <v-text-field
                      :rules="emailRules"
                      solo
                      v-model.trim="memberData.memberInfo.email"
                      
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-md-6 pb-0 pt-0">
                    <label class="font text-gray" for="">Phone</label>
                      <v-text-field
                      :rules="phoneRules"
                      v-model.trim="memberData.memberInfo.phone"
                      @input="formatPhoneNumber"
                      maxlength="12"
                      placeholder="Ex. (999) 999-9999"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-md-6 pb-0 pt-0">
                    <label class="font text-gray" for="">Home Zip Code</label>
                    <v-text-field
                      v-model.trim="memberData.memberInfo.zipCode"
                      :rules="zipRules"
                      disabled
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-md-6 pb-0 pt-0">
                    <label class="font text-gray" for="">Apaly unique ID</label>
                    <v-text-field
                      v-model.trim="memberData.id"
                      solo
                      disabled
                    ></v-text-field>
                    </div>
                    <div class="col-12 col-md-6 pb-0 pt-0">
                    <label class="font text-gray" for="">Location Attribution</label>
                    <v-text-field
                      v-model.trim="memberData.memberInfo.location"
                      solo
                      disabled
                    ></v-text-field>
                  </div>
                  <!-- <div class="col-12 d-md-flex">
                    <label for="">Is This Member still active?</label>
                    <div class="d-flex ml-4">
                      <v-icon v-if="memberData.status == 'active'" v-text="'mdi-check'"></v-icon>
                      
                      <v-icon v-if="memberData.status !== 'active'" v-text="'mdi-close'"></v-icon>
                      <v-switch
                        color="primary"
                        inset
                        v-model.trim="memberData.memberInfo.status"
                        solo
                        hide-details
                        class="no-padding ml-2 mr-2 vendors-switch"
                      ></v-switch>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>

            <v-divider class="mt-5 mb-5"></v-divider>

            <!-- Employer and Plan Info -->
            <div class="row">
              <div class="col-12 col-md-4">
                <h3>Employer and Plan Information</h3>
                <p>Enter details for member information</p>
              </div>
              <div class="col-12 col-md-8">
                <div class="row">
                  <div class="col-12 pb-0 pt-0">
                    <label class="font text-gray" for="">Company Name </label>
                    <v-text-field
                      disabled
                      v-model.trim="memberData.employerPlanInfo.companyName"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 pb-0 pt-0">
                    <label class="font text-gray" for="">Member ID</label>
                    <v-text-field
                      disabled
                      v-model.trim="memberData.employerPlanInfo.memberId"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-md-8 pb-0 pt-0">
                    <label class="font text-gray" for=""
                      >Relationship to the Subscriber
                    </label>
                    <v-text-field
                      disabled
                      v-model.trim="memberData.employerPlanInfo.subscriberRelation"
                      solo
                    ></v-text-field>
                  </div>
                  <div v-if="memberData.employerPlanInfo.subscriber" class="col-12 col-md-4 pb-0 pt-0">
                    <label class="font text-gray" for="">Subscriber </label>
                    <v-text-field
                      disabled
                      v-model.trim="memberData.employerPlanInfo.subscriber.id"
                      @click="goToSubsciber(memberData.employerPlanInfo.subscriber.id)"
                      solo
                    ></v-text-field>
                  </div>
                  <!-- <div class="col-12 pb-0 pt-0">
                    <label class="font text-gray" for="">Plan ID</label>
                    {{ memberData.employerPlanInfo.planName }} <br>
                    {{ memberData.employerPlanInfo.planType }} <br>
                    {{ memberData.employerPlanInfo.planStatus }} <br>
                    <v-text-field
                      disabled
                      v-model.trim="memberData.employerPlanInfo.planId"
                      solo
                    ></v-text-field>
                  </div> -->
                  <div class="col-12 pb-0 pt-0">
                    <label class="font text-gray" for="">Plan Name</label>
                    <v-text-field
                      required
                      disabled
                      v-model.trim="memberData.employerPlanInfo.planName"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 pb-0 pt-0">
                    <label class="font text-gray" for="">Plan Type</label>
                    <v-text-field
                      required
                      disabled
                      v-model.trim="memberData.employerPlanInfo.planType"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 pb-0 pt-0">
                    <label class="font text-gray" for="">Plan Status</label>
                    <v-text-field
                      required
                      disabled
                      v-model.trim="memberData.employerPlanInfo.planStatus"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 pb-0 pt-0">
                    <label class="font text-gray" for="">Group ID</label>
                    <v-text-field
                      required
                      disabled
                      v-model.trim="memberData.employerPlanInfo.groupId"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 pb-0 pt-0">
                    <label class="font text-gray" for="">Payer ID</label>
                    <v-text-field
                      required
                      disabled
                      v-model.trim="memberData.employerPlanInfo.payerId"
                      solo
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </v-form>
        <div class="row no-gutters">
          <!-- History components -->
          <member-history v-if="memberData" :memberData="memberData" />

          <!-- Actions components -->
          <member-actions v-if="memberData && canPerformActions(memberData)" :memberData="memberData" />
        </div>

      </div>

      <!-- Buttons -->
      <div class="col-md-12 mt-5 no-padding">
        <div class="row margin-l-11 pr-10">
          <div class="text-end justify-end col-md-12 mt-5 mb-5">
            <span class="mr-10 pointer" style="color: #0572f3" @click="$router.push('/dpc-providers/members')">
              Cancel
            </span>
            <v-btn class="mr-3 pointer primary pl-10 pr-10"  @click="update()" :disabled="!canPerformActions(memberData)">
              Save
            </v-btn>
          </div>
        </div>
      </div>

    </div>

    <!-- Subscription Tab -->
    <div v-if="tab == 'subscription'" class="member-subscription-details width-100">
      <subscription v-if="memberData" :memberData="memberData" />
    </div>

    <!-- Dependents Tab -->
    <div v-if="tab == 'dependents'" class="member-subscription-details width-100">
      <dependents v-if="memberData" :memberData="memberData" />
    </div>

    <!-- Error Modal -->
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"> </errorModal>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import Subscription from "./Subscription.vue";

import Dependents from "./Dependents.vue";
import MemberHistory from "./MemberHistory.vue";
import MemberActions from "./MemberActions.vue";
import FullscreenLoader from "../../../Common/FullscreenLoader.vue";

import moment from 'moment'
export default {
  components: { successModal, removeDialog, errorModal, Subscription,MemberHistory,MemberActions,Dependents, FullscreenLoader },
  data() {
    return {
      tab: "details",
      memberLocation: '',
      loader: false,
      memberData:{
        memberInfo:{
          
        },
        employerPlanInfo:{},
        history:{},
        subscription:{},
      },
      dialog: false,
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Enter a valid number",
      ],
      states: [],
      discountRate: "",
      valid: false,
      zipRules: [(v) => (v && v.length == 5) || " Zip Code should be five digits long"],
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },

  created() {
  if(this.$route.query.token){
    this.$cookies.set("token", this.$route.query.token);
    localStorage.setItem("type",'DPC');
      api()
        .get(`/dpcproviders/account`)
        .then((res) => {
          if (res.data) {
          res.data.firstName = res.data.primaryContact.firstName;
          res.data.lastName = res.data.primaryContact.lastName;
          res.data.role = 'admin';
          localStorage.setItem("user", JSON.stringify(res.data));
          }
        });
  }
    this.getMember();
    this.getLocations();
  },
  computed: {
    returnFullGender(){
        if(this.memberData.memberInfo.gender == 'f'){
          return 'Female'
        }else{
          return 'Male'
        }
    }, 
    returnFormattedDate(){
      return moment(this.memberData.memberInfo.dob).format('MM/DD/YYYY')
    },
  },
  methods: {
    canPerformActions(item) {
        return item?.employerPlanInfo?.planStatus != 'error' && item?.history?.membershipStatus != 'canceled';
    },
    formatPhoneNumber(event) {
      this.memberData.memberInfo.phone = this.formatAsPhoneNumber(event);
    },
    formatAsPhoneNumber(value) {
      value = value.replace(/\D/g, "");
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      value = value.slice(0, 3) + " " + value.slice(3, 6) + " " + value.slice(6);
      return value;
    },
    getMember(){
      this.loader = true;
      api()
          .get(`/dpcproviders/member/`+this.$route.params.id)
          .catch((err) => {
            if (err) {
              this.loader = false;
              if (err.response.status == 500) {
                this.$refs.error_modal.openPopUp(
                  "There was an error, system admin is notified. Please try again later."
                );
              }
              console.log("getMember() Error: ", err.response.data ? err.response.data : err.response);
            }
          })
          .then((res) => {
            if(res){
              this.loader = false;
              this.memberData = res.data;
            }
          })
          .finally(() => {
            this.loader = false;
              });;
    },
    getLocations() {
      api()
        .get("/dpcproviders/locations")
        .then((res) => {
          const foundLocation = res.data.find(location => location.id === this.memberData.attribution?.location);
          this.memberLocation = foundLocation?.name;
        });
    },
    parseDate(date) {
      const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    changeTab(tab) {
      this.tab = tab;
    },
    generatePdf() {
      window.open("https://api.apaly.net/terms");
    },
    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },

    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/dpc-providers/members/");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    goToSubsciber(subsciber){
      this.$router.push(`/dpc-providers/members/member/${subsciber}`);
    },
    update() {
      this.$refs.form.validate();
      let data = {
        // firstName: this.memberData.memberInfo.firstName,
        // middleName: this.memberData.memberInfo.middleName,
        // lastName: this.memberData.memberInfo.lastName,
        // gender: this.memberData.memberInfo.gender,
        phone: this.memberData.memberInfo.phone,
        email: this.memberData.memberInfo.email,
        // zipCode: this.memberData.memberInfo.zipCode,
        // status: this.memberData.memberInfo.status,
        // companyName: this.memberData.employerPlanInfo.companyName,
        // payerId: this.memberData.employerPlanInfo.payerId,
        // groupId: this.memberData.employerPlanInfo.groupId,
        // planId: this.memberData.employerPlanInfo.planId,
        // memberId: this.memberData.employerPlanInfo.memberId,
      };
      // console.log("update member info: ", data);
      
      api()
        .put(`/dpcproviders/members/` + this.$route.params.id, data)
        .catch((error) =>{
          if(error){
            console.log("update() Error: ", error);
            this.$refs.error_modal.openPopUp(error.response.data.error.message)
          }
             
        })
        .then((res) => {
          if (res) {
            // console.log("Successfully updated member info: ", res);
            this.$refs.success_modal.openPopUp("Member updated successfully");
          }
        });
 
      // if (this.valid) {

      // }
    },
  },
};
</script>
<style scoped>


</style>

