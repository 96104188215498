<template>
  <div class="text-center">
    <v-dialog persistent v-model.trim="dialog" width="800" class="success-modal">
      <v-card>
        <v-card-text class="success-modal">
          <div class="text-center">
            <img src="/sucess.png" alt="" />
          </div>
          <h2 class="text-center font mt-2">
            <h3>{{ title?.message || title }}</h3>
          </h2>
          <h2
            class="text-center text-underline blue-light mt-10 pointer"
            @click="$emit('confirm')"
          >
            Done
          </h2>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      title: "",
      text: "",
    };
  },
  methods: {
    closePopUp() {
      this.dialog = false;
    },
    openPopUp(title, text) {
      this.title = title;
      this.text = text;
      this.dialog = true;
    },
  },
};
</script>

<style>
</style>