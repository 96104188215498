<template>
    <div class="col-md-6 mt-2" v-if="memberData">
        <p class="font-weight-bold">History</p>
        <div class="member-detail-history">
          <div class="row">
            <div class="col-12 col-md-4 ">
              Next Visit Date
            </div>
            <div class="col-12 col-md-8 ">
              {{ memberData.history.nextVisit | moment("MM/DD/YYYY") }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4 ">
              Eligibility Status
            </div>
            <div class="col-12 col-md-8 ">
               <span style="color:#238912">{{memberData.history?.eligibility?.status}}</span> - Last checked on
            {{ memberData.history?.eligibility?.date | moment("MM/DD/YYYY") }}
            <v-btn class="mt-2" @click="storeMember" v-if="canPerformActions(memberData)">
              <img class="mr-2" src="/svg/refresh-arrow.svg" /> Check Eligibity Status
            </v-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4 ">
              Deductible Balance
            </div>
            <div class="col-12 col-md-8 ">
              $ {{ memberData.history?.deductible }} <small>(as of {{  memberData.history?.eligibility?.date |  moment("MM/DD/YYYY") }})</small>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4 ">
              Subscription
            </div>
            <div class="col-12 col-md-8 ">

            <span v-if="memberData.history?.subscription">{{ memberData.history?.subscription?.status.charAt(0).toUpperCase() + memberData.history?.subscription?.status.slice(1)}}</span>
            <span v-if="memberData.history?.subscription && memberData.history?.date"> until {{ memberData.history?.subscription?.date | moment("MM/DD/YYYY") }}
          </span>
          </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4 ">
              Attribution Status
            </div>
            <div class="col-12 col-md-8 ">
                <span style="color:#cd0c0c" v-if="memberData.attribution?.status === 'canceled'">
                    {{memberData.attribution?.status}}
                </span> 
                <span style="color:#238912" v-else>
                    {{memberData.attribution?.status}}
                </span> 
                <span v-if="memberData.attribution?.status === 'canceled'" >
                    - Term Date
                    {{ memberData.attribution?.termDate | moment("MM/DD/YYYY") }}
                </span>
            </div>
          </div>

        </div>
    </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, removeDialog, errorModal },
  props:{
    memberData:{
        required:true,
        type:Object
    }
  },
  data() {
    return {
        member:{}

    };
  },
  created() {
    this.member = this.memberData

  },
  methods: {
    canPerformActions(item) {
        return item?.employerPlanInfo?.planStatus != 'error' && item?.history?.membershipStatus != 'canceled';
    },
    storeMember() {
      this.$store.commit("storeMemberInfo", this.memberData);
      setTimeout(() => {
        this.$router.push("/dpc-providers/home");
      }, 1000);
    },

    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/dpc-providers/members/");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
  },
};
</script>
<style lang="scss">
.member-detail-history{
    background-color: #f5f6f8;
    width:75%;
    padding: 20px;
  }
  @media only screen and (max-width: 600px) {
    .member-detail-history{
      width:100%;
    }
  }
</style>